html body {
    width: 100%;
    margin: 0px;
}

#root {
    display: flex;
    justify-content: center;
    background-color: #efeef5;
}

.fake-container {
    height: 95px;
    /*padding: 10px;*/
}

@media only screen and (min-width: 500px) {
    .main-container {
        max-width: 1000px;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.ch {
    color: white;
    text-decoration: none;
    font-size: 14px;
    margin-left: 25px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.main-toolbar {
    display: flex;
    justify-content: right;
}

p {
    text-align: justify!important;
    word-break: break-word;
}
h1, p {
    font-family: 'Roboto Condensed', sans-serif;
}

b {
    margin: 20px 0px!important;
    font-weight: bold!important;
}

h4 {
    margin: 20px 0px!important;
}

i {
    margin-bottom: 30px!important;
}

.footer-item>p {
    color: white;
    font-size: 14px;
}
.footer-item>a {
    color: white;
    font-size: 14px;
}

header {
    background-color: #53BFBB!important;
}

.MuiAppBar-colorPrimary-16 {
    background-color: #53BFBB!important;
}

.paper {
    padding: 20px;
    margin-top: 40px;
}

.image-grid {
    text-align: center;
}

.text-grid{
    display: flex;
    align-items: center;
}

.divider {
    margin-top: 30px!important;
    margin-bottom: 20px!important;
}

.icon {
    background-color: #A90001!important;
}

